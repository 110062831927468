import * as noUiSlider from "nouislider/dist/nouislider.min";

export default function (cell, onRendered, success, cancel, editorParams) {
  const container = document.createElement("div");
  container.setAttribute("class", "numberrangeinput");
  const slider = document.createElement("div");
  const valuesDisplay = document.createElement("div");
  valuesDisplay.style.display = "flex";
  valuesDisplay.style.gap = "1rem";
  valuesDisplay.style.justifyContent = "center";

  const formatValue = (value) => {
    if (editorParams.formatValue) return editorParams.formatValue(value);
    return value.toFixed(2);
  };

  const value = cell.getValue();
  const startValue = value ? value.start : editorParams.min;
  const endValue = value ? value.end : editorParams.max;

  const inputFrom = document.createElement("input");
  inputFrom.type = "number";
  inputFrom.value = startValue;
  inputFrom.style.maxWidth = "80px";
  inputFrom.style.width = "40%";

  const inputTo = inputFrom.cloneNode();
  inputTo.value = endValue;

  const sliderInstance = noUiSlider.create(slider, {
    connect: true,
    start: [startValue, endValue],
    range: {
      min: [editorParams.min],
      max: [editorParams.max],
    },
    format: {
      to: formatValue,
      from: (value) => Number(value),
    },
  });

  cell
    .getColumn()
    .getElement()
    .addEventListener("numberCellEdited", function (event) {
      editorParams.min = event.detail.newMin;
      editorParams.max = event.detail.newMax;

      sliderInstance.updateOptions({
        range: {
          min: event.detail.newMin,
          max: event.detail.newMax,
        },
      });
      cell.getTable().replaceData();
    });

  slider.noUiSlider.on("update", function (values) {
    inputFrom.value = values[0];
    inputTo.value = values[1];
  });

  slider.noUiSlider.on("change", function (values) {
    success({ start: values[0], end: values[1] });
  });

  slider.noUiSlider.on("set", function (values) {
    success({ start: values[0], end: values[1] });
  });

  inputFrom.addEventListener("change", function (e) {
    slider.noUiSlider.set([e.target.value, null], true);
  });

  inputFrom.addEventListener("keydown", function (e) {
    if (e.keyCode === 13) {
      slider.noUiSlider.set([e.target.value, null], true);
    }
  });

  inputTo.addEventListener(
    "change",
    function (e) {
      slider.noUiSlider.set([null, e.target.value], true);
    },
    true,
  );

  inputTo.addEventListener(
    "keydown",
    function (e) {
      if (e.keyCode == 13) {
        slider.noUiSlider.set([null, e.target.value], true);
      }
    },
    true,
  );

  valuesDisplay.appendChild(inputFrom);
  valuesDisplay.appendChild(inputTo);
  container.appendChild(valuesDisplay);
  container.appendChild(slider);
  return container;
}

import Gantt from "frappe-gantt/src/index";
import date_utils from "frappe-gantt/src/date_utils";
import { $, createSVG } from "frappe-gantt/src/svg_utils";

Gantt.prototype.setup_tasks = function (tasks) {
  this.tasks = tasks.map((task, i) => {
    task._start = date_utils.parse(task.start);
    task._end = date_utils.parse(task.end);

    if (date_utils.diff(task._end, task._start, "year") > 10) {
      task.end = null;
    }

    task._index = i;
    if (typeof task.row_id === "number") {
      task._index = task.row_id;
    }

    if (!task.start && !task.end) {
      const today = date_utils.today();
      task._start = today;
      task._end = date_utils.add(today, 2, "day");
    }

    if (!task.start && task.end) {
      task._start = date_utils.add(task._end, -2, "day");
    }

    if (task.start && !task.end) {
      task._end = date_utils.add(task._start, 2, "day");
    }

    const task_end_values = date_utils.get_date_values(task._end);
    if (task_end_values.slice(3).every((d) => d === 0)) {
      task._end = date_utils.add(task._end, 24, "hour");
    }

    if (!task.start || !task.end) {
      task.invalid = true;
    }

    if (typeof task.dependencies === "string" || !task.dependencies) {
      let deps = [];
      if (task.dependencies) {
        deps = task.dependencies
          .split(",")
          .map((d) => d.trim())
          .filter((d) => d);
      }
      task.dependencies = deps;
    }

    if (!task.id) {
      task.id = generate_id(task);
    }

    return task;
  });

  this.setup_dependencies();
};

Gantt.prototype.make_grid_rows = function () {
  let counter_rows = 0;
  const distinctRows = [...new Set(this.tasks.map((x) => x.row_id))];
  for (let row of distinctRows) {
    counter_rows = counter_rows + 1;
  }
  const rows_layer = createSVG("g", { append_to: this.layers.grid });
  const lines_layer = createSVG("g", { append_to: this.layers.grid });
  const row_width = this.dates.length * this.options.column_width;
  const row_height = this.options.bar_height + this.options.padding;

  let row_y = this.options.header_height + this.options.padding / 2;

  for (let row of distinctRows) {
    createSVG("rect", {
      x: 0,
      y: row_y,
      width: row_width,
      height: row_height,
      class: "grid-row",
      append_to: rows_layer,
    });

    createSVG("line", {
      x1: 0,
      y1: row_y + row_height,
      x2: row_width,
      y2: row_y + row_height,
      class: "row-line",
      append_to: lines_layer,
    });

    row_y += this.options.bar_height + this.options.padding;
  }
};

Gantt.prototype.make_grid_background = function () {
  const grid_width = this.dates.length * this.options.column_width;
  const distinct_rows = [...new Set(this.tasks.map((x) => x.row_id))];
  const grid_height =
    this.options.header_height +
    this.options.padding +
    (this.options.bar_height + this.options.padding) * distinct_rows.length;

  createSVG("rect", {
    x: 0,
    y: 0,
    width: grid_width,
    height: grid_height,
    class: "grid-background",
    append_to: this.layers.grid,
  });

  $.attr(this.$svg, {
    height: grid_height + this.options.padding - 22,
    width: "100%",
  });
};

Gantt.prototype.make_grid_highlights = function () {
  if (this.view_is("Day")) {
    const width = this.options.column_width;
    const height =
      (this.options.bar_height + this.options.padding) * this.tasks.length +
      this.options.header_height +
      this.options.padding / 2;

    let x = 0;

    for (let date of this.dates) {
      let y = this.options.header_height + this.options.padding / 2;

      let isToday = date.getTime() === this.options.date_now.getTime();
      let isHoliday = false;
      let isSaturday = date.getDay() === 6;
      const holidays = new bundle.Holidays(this.options.country, {
        types: ["public"],
      });
      holidays.getHolidays(date.getFullYear()).forEach(function (element) {
        const holyDate = new Date(element.date);
        if (holyDate.getTime() === date.getTime() || date.getDay() === 0)
          isHoliday = true;
      });
      let className;

      if (isToday) {
        className = "today-highlight";
        y = (this.options.header_height + this.options.padding) / 2;
      } else if (isHoliday) {
        className = "holiday-highlight";
      } else if (isSaturday) {
        className = "saturday-highlight";
      }

      if (isToday || isHoliday || isSaturday) {
        createSVG("rect", {
          x,
          y,
          width,
          height,
          class: className,
          append_to: this.layers.grid,
        });
      }
      x += this.options.column_width;
    }
  }
};

Gantt.prototype.make_dates = function () {
  for (let date of this.get_dates_to_draw()) {
    let lower_text = "";

    if (date.holiday) lower_text = "lower-text lower-text-holiday";
    else if (date.saturday) lower_text = "lower-text lower-text-saturday";
    else lower_text = "lower-text";

    createSVG("text", {
      x: date.lower_x,
      y: date.lower_y,
      innerHTML: date.lower_text,
      class: lower_text,
      append_to: this.layers.date,
    });

    if (date.upper_text) {
      const $upper_text = createSVG("text", {
        x: date.upper_x,
        y: date.upper_y,
        innerHTML: date.upper_text,
        class: "upper-text",
        append_to: this.layers.date,
      });

      if ($upper_text.getBBox().x2 > this.layers.grid.getBBox().width) {
        $upper_text.remove();
      }
    }
  }
};

Gantt.prototype.get_dates_to_draw = function () {
  let last_date = null;
  const dates = this.dates.map((date, i) => {
    const d = this.get_date_info(date, last_date, i);
    last_date = date;
    const holidays = new bundle.Holidays(this.options.country, {
      types: ["public"],
    });
    holidays.getHolidays(date.getFullYear()).forEach(function (element) {
      const holyDate = new Date(element.date);
      if (holyDate.getTime() === date.getTime() || date.getDay() === 0)
        d.holiday = true;
      else if (date.getDay() === 6) {
        d.saturday = true;
      }
    });
    return d;
  });
  return dates;
};

Gantt.prototype.make_grid = function () {
  this.make_grid_background();
  this.make_grid_rows();
  this.make_grid_header();
  this.make_grid_highlights();
  this.make_grid_ticks();
};

Gantt.prototype.bind_bar_events = function () {};
Gantt.prototype.update_view_scale = function () {};

export function make_gantt(tasks, country, date_now) {
  return new Gantt("#gantt", tasks, {
    popup_trigger: null,
    column_width: 50,
    bar_height: 27,
    country: country,
    date_now: date_now,
  });
}

export default function (cell, onRendered, success, cancel, editorParams) {
  const cellValue = cell.getValue();
  const editor = cell.getElement().lastChild;

  editor.setAttribute("type", "text");
  editor.value = cellValue;

  onRendered(function () {
    editor.focus();
  });

  function onChange(e) {
    if (editor.value != cellValue) {
      success(editor.value);
    } else {
      cancel();
    }
  }

  //submit new value on blur or change
  editor.addEventListener("change", onChange);
  editor.addEventListener("blur", onChange);

  return editor;
}

import { initializeDateTimeFields } from "../../datetime";
export default function (cell, onRendered, success, cancel, editorParams) {
  const container = document.createElement("div");
  const input = document.createElement("input");
  input.setAttribute("class", "daterangeinput");

  const value = cell.getValue();
  if (value) {
    input.value =
      value.start && value.end ? `${value.start} to ${value.end}` : value;
  }
  input.style.boxSizing = "border-box";
  input.style.width = "100%";
  function buildValues() {
    const values = input.value.split(" to ");
    if (values.length === 1) success(values[0]);
    else success({ start: values[0], end: values[1] });
  }
  input.addEventListener("change", buildValues);
  container.appendChild(input);
  initializeDateTimeFields(container, editorParams);
  return container;
}

import { addAlert } from "../../alerts";
import { patchPrompt } from "../../prompts";

export default function (cell, formatterParams, onRendered) {
  let url = formatterParams.url;
  const id = cell.getRow().getData().id;
  const str_id = `allert_${cell.getRow().getData().id}`;
  if (url.includes("__id__")) {
    url = url.replace("__id__", id);
  }
  document.addEventListener(
    "click",
    async function (e) {
      if (e.target.closest(".trigger-button") && e.target.id === str_id) {
        e.stopPropagation();

        patchPrompt({
          options: {
            title: gettext("Confirmation"),
            html: `${gettext("Are you sure you want to accept this route?")}
                   <small>${gettext(
                     "You won't be able to revert this action!",
                   )}</small>`,
            confirmText: gettext("Yes, accept it!"),
          },
          url: url,
          successAction: function (response) {
            addAlert("success", response.data.message);
            cell.getRow().getTable().setData();
          },
        });
      }
    },
    true,
  );
  return `<button id="${str_id}" class="btn-table-full btn-secondary trigger-button" ${
    formatterParams.activeStatus === cell.getValue() && "disabled"
  }>${formatterParams.text}</button>`;
}

import { axios } from "../project";
import { getCookie } from "../utils";

export function initializeTracking() {
  if (navigator.geolocation) {
    try {
      const config = getConfig();
      if (config.trip === null) return;
      getCurrentPosition();
    } catch (e) {
      console.log("Geolocation is disabled.");
    }
  }
}

function getConfig() {
  return JSON.parse(document.querySelector("#geolocationConfig").textContent);
}

function getCurrentPosition() {
  const config = getConfig();
  const options = {
    enableHighAccuracy: config.high_accuracy,
    maximumAge: 5000,
    timeout: 30000,
  };
  navigator.geolocation.getCurrentPosition(
    getPositionSuccess,
    getPositionError,
    options,
  );
}

async function getPositionSuccess(position) {
  const config = getConfig();
  await savePosition(position, config);
  setTimeout(getCurrentPosition, config.interval);
}

async function savePosition(position, config) {
  const payload = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  };
  await axios.post(
    document.querySelector("#geolocation-add-api").value,
    payload,
    {
      headers: {
        "X-CSRFTOKEN": getCookie("csrftoken"),
      },
    },
  );
}

function getPositionError() {
  const config = getConfig();
  setTimeout(getCurrentPosition, config.interval);
}

initializeTracking();

export default {
  initializeTracking,
};

import { textEmailFilter } from "./signup-invite.js";
import { initializeDateTimeFields, initializeAllDayCheckbox } from "./datetime";
import { initializeChoices } from "./choices";
import { setInnerHTML } from "./utils";
import { initializeFileUpload } from "./files";

const modalRoot = document.getElementById("modalContainer");

export function createModal(title, content) {
  const template = `
    <div class="modal show">
        <div class="modal__content">
          <div class="modal__header">
            <span class="modal__close">&times;</span>
            <h2>${title}</h2>
          </div>
          <div class="modal__body">
            ${content}
          </div>
        </div>
    </div>
    `;
  setInnerHTML(modalRoot, template);
  const modal = modalRoot.querySelector(".modal");
  document.body.style.overflowY = "hidden";
  return modal;
}

export function initializeModalForm(
  modal,
  method,
  dataTimeFieldFormats,
  onSubmit,
) {
  initializeDateTimeFields(modal, dataTimeFieldFormats);
  initializeAllDayCheckbox(modal);
  initializeChoices(modal);
  initializeFileUpload(modal);
  const form = modal.querySelector("form");
  form.addEventListener("submit", async function (e) {
    e.preventDefault();
    if (onSubmit) {
      return onSubmit(form);
    }
    const formData = new FormData(form);
    const response = await fetch(form.action, {
      method: "post",
      body: formData,
      headers: {
        ajax: true,
      },
    });
    if (response.status === 200) {
      const result = await response.json();
      const createEvent = new CustomEvent("modal-form-saved", {
        detail: result,
      });
      modal.dispatchEvent(createEvent);
      closeModal();
    } else {
      const htmlForm = await response.text();
      modal.querySelector(".modal__body").innerHTML = htmlForm;
      if (method === "invite") {
        textEmailFilter();
        initializeModalForm(modal, "invite", dataTimeFieldFormats);
      } else {
        initializeModalForm(modal, undefined, dataTimeFieldFormats);
      }
    }
  });
}

export function closeModal() {
  const modal = modalRoot.querySelector(".modal");
  if (modal) {
    modal.classList.remove("show");
    modal.classList.add("hide");
    document.body.style.overflowY = "";
    setTimeout(function () {
      modal.remove();
    }, 200);
  }
}

document.addEventListener("click", function (e) {
  const target = e.target;
  if (
    target.classList.contains("modal") ||
    target.classList.contains("modal__close")
  ) {
    closeModal();
  }
});

export default {
  createModal,
  initializeModalForm,
  closeModal,
};

import { isSidebarVisible } from "../utils";
import {
  initializeAllDayCheckbox,
  initializeDateTimeFields,
} from "../datetime";
import { initializeChoices } from "../choices";
import { initializeFileUpload } from "../files";
import { addAlert } from "../alerts";
import { initializeNumberSpinner } from "../interface";

function initializeToggles(form) {
  const togglesScript = form
    .closest(".multiform-wrapper")
    .querySelector("script");
  const prefix = togglesScript.id;
  const toggles = JSON.parse(togglesScript.textContent);
  toggles.forEach((toggle) => {
    const toggleInput = form.querySelector(`#id_${prefix}-${toggle[0]}`);
    const affectedInput = form.querySelector(`#id_${prefix}-${toggle[1]}`);

    toggleInput.addEventListener("change", function (e) {
      affectedInput.disabled = !toggleInput.checked;
    });
  });
}

export function initializeMultiformSaveBanner(element) {
  const sidebar = document.querySelector("#sidebar");
  const forms = document.querySelectorAll("form.multiform");
  let modifiedForms = [];

  forms.forEach(function (form) {
    const isDisabled =
      form.closest(".multiform-wrapper").dataset.disabled === "True";
    if (isDisabled) return;
    form.addEventListener("change", function (e) {
      if (!modifiedForms.includes(form)) {
        element.classList.remove("hidden");
        element.classList.add("shown");
        modifiedForms.push(form);
      }
    });

    initializeToggles(form);
  });

  sidebar.addEventListener("toggled", function (e) {
    if (e.detail) {
      element.classList.add("sidebar-margin");
    } else {
      element.classList.remove("sidebar-margin");
    }
  });

  if (isSidebarVisible()) element.classList.add("sidebar-margin");

  window.onscroll = function () {
    if (
      window.innerHeight + Math.round(window.scrollY) + 30 >=
      document.body.offsetHeight
    ) {
      element.classList.add("bottom");
    } else {
      element.classList.remove("bottom");
    }
  };

  element
    .querySelector(".btn-submit")
    .addEventListener("click", async function () {
      let hasError = false;
      let errorForm = null;

      for (const form of modifiedForms) {
        let badge = form.querySelector(".badge");
        if (badge) {
          badge.textContent = gettext("SAVING...");
          badge.classList = "badge saving";
        }

        const formData = new FormData(form);
        try {
          const response = await fetch(form.action, {
            method: "post",
            body: formData,
            headers: {
              ajax: true,
            },
          });
          const htmlForm = await response.text();
          form.innerHTML = htmlForm;
          initializeDateTimeFields(form, dataTimeFieldFormats);
          initializeAllDayCheckbox(form);
          initializeChoices(form);
          initializeFileUpload(form);
          initializeToggles(form);
          initializeNumberSpinner();
          if (response.status === 200) {
            modifiedForms = modifiedForms.filter((_form) => _form !== form);
            element.classList.remove("shown");
            setTimeout(function () {
              element.classList.add("hidden");
            }, 100);
          } else if (!hasError) {
            hasError = true;
            errorForm = form;
          }
        } catch {
          hasError = true;
          errorForm = form;
        }
      }
      if (!hasError) {
        addAlert("success", gettext("Trip was updated successfully."));
      } else {
        addAlert("error", gettext("Issues occurred while saving changes."));
        const badge = errorForm.querySelector(".badge");
        if (badge) {
          badge.textContent = gettext("ERROR");
          badge.classList = "badge error";
        }
        errorForm.scrollIntoView();
      }
    });

  window.addEventListener("beforeunload", function (e) {
    if (modifiedForms.length > 0) {
      e.preventDefault();
      e.returnValue = "";
    }
  });
}

export default {
  initializeMultiformSaveBanner,
};

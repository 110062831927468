import ClearAllFiltersMenu from "./ClearAllFiltersMenu";
import ShowAllColumnsMenu from "./ShowAllColumnsMenu";

export default function () {
  const menu = [
    ClearAllFiltersMenu()[0],
    ShowAllColumnsMenu()[0],
    {
      separator: true,
    },
  ];
  const columns = this.getColumns();

  for (let column of columns) {
    if (column.getDefinition().frozen) continue;
    //create checkbox element using font awesome icons
    const columnSelect = `<div class="checkbox-cell small">
        <input type="checkbox" ${column.isVisible() ? "checked" : ""}>
        <span>${column.getDefinition().title}</span>
    </div>`;

    //create menu item
    menu.push({
      label: columnSelect,
      action: function (e) {
        //prevent menu closing
        e.stopPropagation();

        //toggle current column visibility
        column.toggle();
        e.target
          .closest(".tabulator-menu-item")
          .querySelector(".checkbox-cell input").checked = column.isVisible();
      },
    });
  }

  return menu;
}

import { initializeDateTimeFields } from "../../datetime";
export default function (cell, onRendered, success, cancel, editorParams) {
  const container = document.createElement("div");
  container.style.textAlign = "start";
  const inputTime = document.createElement("input");
  inputTime.setAttribute("class", "timeinput");
  inputTime.style.width = "100%";
  inputTime.style.padding = "4px";
  inputTime.style.boxSizing = "border-box";
  inputTime.value = cell.getValue();
  function buildValues() {
    success(inputTime.value);
  }
  inputTime.addEventListener("change", buildValues);
  container.appendChild(inputTime);
  initializeDateTimeFields(container, editorParams);
  return container;
}

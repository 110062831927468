export default function () {
  return [
    {
      label: gettext("Clear all filters"),
      action: function (e, column) {
        column.getTable().clearHeaderFilter();
      },
    },
  ];
}

export default function () {
  return [
    {
      label: gettext("Hide Column"),
      action: function (e, column) {
        column.hide();
      },
    },
  ];
}

export default function (cell, formatterParams, onRendered) {
  const button = document.createElement("button");
  button.className = "btn-table-full";
  button.innerHTML = "<i class='fa-solid fa-bars'></i>";
  const that = this;

  button.addEventListener("click", function (e) {
    e.stopPropagation();
    const event = new Event("row-contextmenu");
    event.touches = [
      {
        pageX: e.pageX,
        pageY: e.pageY,
      },
    ];

    that.dispatch("row-contextmenu", event, cell.getRow());
  });
  return button;
}

function initializeInvite() {
  document
    .getElementById("invite")
    .addEventListener("click", async ({ target }) => {
      const response = await fetch(target.dataset.url, {
        headers: {
          ajax: true,
        },
      });
      const createForm = await response.text();
      const modal = bundle.modals.createModal(
        gettext("Send signup invite"),
        createForm,
      );
      textEmailFilter();
      bundle.modals.initializeModalForm(modal, "invite");
      modal.addEventListener("modal-form-saved", function (e) {
        window.location.href = e.detail.success_url;
      });
    });
}
if (document.getElementById("invite")) {
  initializeInvite();
}

export function textEmailFilter() {
  new bundle.choices.Choices("#id_emails", {
    allowHTML: true,
    editItems: true,
    addItemFilter: function (value) {
      if (!value) {
        return false;
      }

      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const expression = new RegExp(regex.source, "i");
      return expression.test(value);
    },
  });
}

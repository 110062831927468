export default function (cell, formatterParams, onRendered) {
  let url = formatterParams.url;
  if (url.includes("__id__")) {
    url = url.replace("__id__", cell.getRow().getData().id);
  }
  const classes = ["btn-table-full", "edit-button"];
  if (
    "disabledCondition" in formatterParams &&
    formatterParams.disabledCondition(cell)
  ) {
    classes.push("btn-disabled");
    url = "javascript:void(0)";
  }
  return `<a class="${classes.join(" ")}" href="${url}">${
    formatterParams.text
  }</a>`;
}

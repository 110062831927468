export default function () {
  return [
    {
      label: gettext("Clear filter"),
      action: function (e, column) {
        column.setHeaderFilterValue("");
      },
    },
  ];
}

let sideNavClicked = false;

export function initializeSideNavigation(element) {
  const observer = new IntersectionObserver(
    (entries) => {
      if (sideNavClicked) return;
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        const id = entry.target.getAttribute("id");
        const navEntry = document.querySelector(
          `nav.side-navigation a[href="#${id}"]`,
        );
        if (navEntry) {
          if (navEntry.parentElement.classList.contains("active")) return;
          if (navEntry._tippy) {
            navEntry._tippy.show();
            setTimeout(function () {
              navEntry._tippy.hide();
            }, 1000);
          }
          document
            .querySelectorAll("nav.side-navigation a.active")
            .forEach((el) => el.classList.remove("active"));
          navEntry.classList.add("active");
        }
      });
    },
    { rootMargin: "-50% 0% -50% 0%" },
  );

  document.querySelectorAll("div[id]").forEach((anchor) => {
    observer.observe(anchor);
  });

  document.querySelectorAll("nav.side-navigation a").forEach((navEntry) => {
    navEntry.addEventListener("click", function (e) {
      sideNavClicked = true;
      document
        .querySelectorAll("nav.side-navigation a.active")
        .forEach((el) => el.classList.remove("active"));
      navEntry.classList.add("active");
      setTimeout(function () {
        sideNavClicked = false;
      }, 1000);
    });
  });
}

window.addEventListener("DOMContentLoaded", async function () {
  document.querySelectorAll(".side-navigation").forEach((element) => {
    initializeSideNavigation(element);
  });
});

export default {
  initializeSideNavigation,
};

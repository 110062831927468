import tippy from "tippy.js";

export function initializeTooltip(element) {
  tippy(element, {
    content: element.dataset.tooltip,
    placement: element.dataset.tooltipPlacement || "auto",
    popperOptions: {
      strategy: "fixed",
    },
  });
}

export function setTooltips() {
  document.querySelectorAll("[data-tooltip]").forEach((element) => {
    initializeTooltip(element);
  });
}

window.addEventListener("DOMContentLoaded", async function () {
  setTooltips();
});

export default {
  initializeTooltip,
  setTooltips,
};

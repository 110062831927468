export default function (columns, forced) {
  let colsWidth = 0,
    tableWidth = this.table.rowManager.element.clientWidth,
    gap = 0,
    lastCol = false;

  columns.forEach((column, i) => {
    if (!column.widthFixed) {
      column.reinitializeWidth();
    }
    if (!column.getDefinition().frozen) {
      lastCol = column;
    }

    if (column.visible) {
      colsWidth += column.getWidth();
    }
  });

  if (lastCol) {
    const evenMoreMagicValue = columns.length * 11;
    gap = tableWidth - colsWidth + lastCol.getWidth() - evenMoreMagicValue;

    if (gap > 0) {
      lastCol.setWidth(gap);
    } else {
      lastCol.reinitializeWidth();
    }
  }
}

export function initializeAlert(alert, time) {
  alert.querySelector(".btn-close").addEventListener("click", function () {
    alert.remove();
  });
  setTimeout(() => {
    alert.classList.add("hide");
    alert.addEventListener("transitionend", (event) => {
      alert.remove();
    });
  }, time);
}

window.addEventListener("DOMContentLoaded", async function () {
  if (localStorage.getItem("alert")) {
    const parsed_alert = JSON.parse(localStorage.getItem("alert"));
    addAlert(parsed_alert[0], parsed_alert[1]);
    localStorage.removeItem("alert");
  }
  let counter = 0;
  document.querySelectorAll(".alerts .alert").forEach((alert) => {
    counter++;
    initializeAlert(alert, 5000 + counter * 1000);
  });
});

export function addAlert(tag, message) {
  let tagLabel = "";
  switch (tag) {
    case "success":
      tagLabel = gettext("SUCCESS!");
      break;
    case "error":
      tagLabel = gettext("ERROR!");
      break;
    case "warning":
      tagLabel = gettext("WARNING!");
      break;
    case "info":
      tagLabel = gettext("INFORMATION!");
      break;
    default:
      break;
  }
  const alert = `
        <div class="alert alert-${tag}">
            <span class="tag">${tagLabel}</span>
            <span class="text">${message}</span>
            <button type="button" class="btn-close" aria-label=${gettext(
              "Close",
            )}></button>
        </div>
    `;
  document.querySelector(".alerts").insertAdjacentHTML("beforeend", alert);
  initializeAlert(document.querySelector(".alerts .alert:last-child"), 6000);
}

export function addLocalStorageAlert(tag, message) {
  localStorage.setItem("alert", JSON.stringify([tag, message]));
}

export default {
  addAlert,
  initializeAlert,
  addLocalStorageAlert,
};

import axios from "axios";
export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function updateInterfaceState(data) {
  const data_to_send = JSON.stringify(data);
  axios
    .post("/update_interface_settings/", data_to_send, {
      headers: {
        "X-CSRFTOKEN": getCookie("csrftoken"),
        "X-REQUESTED-WITH": "XMLHttpRequest",
      },
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function setInnerHTML(elm, html) {
  elm.innerHTML = html;

  Array.from(elm.querySelectorAll("script")).forEach((oldScriptEl) => {
    const newScriptEl = document.createElement("script");

    Array.from(oldScriptEl.attributes).forEach((attr) => {
      newScriptEl.setAttribute(attr.name, attr.value);
    });

    const scriptText = document.createTextNode(oldScriptEl.innerHTML);
    newScriptEl.appendChild(scriptText);

    oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
  });
}

export async function fetchDataFromHiddenInput(id) {
  const response = await fetch(document.querySelector(id).value);
  return await response.json();
}

export async function fetchDataFromHiddenInputWithParams(id, params) {
  const response = await fetch(
    `${document.querySelector(id).value}?${params ? params : ""}`,
  );
  return await response.json();
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function hasPermission(permission) {
  return document.querySelector(`#perm-${permission}`).value === "True";
}

export async function sendDeleteRequest(url) {
  return axios({
    method: "delete",
    url: url,
    headers: {
      "Content-Type": "application/json",
      "X-CSRFTOKEN": getCookie("csrftoken"),
      Ajax: true,
    },
  });
}

export async function sendPostRequest(url, data) {
  return axios({
    method: "post",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      "X-CSRFTOKEN": getCookie("csrftoken"),
      Ajax: true,
    },
  });
}

export async function sendPatchRequest(url, data) {
  return axios({
    method: "patch",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      "X-CSRFTOKEN": getCookie("csrftoken"),
      Ajax: true,
    },
  });
}

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "&hellip;" : str;
}

export function isSidebarVisible() {
  return !document.querySelector("#sidebar").classList.contains("hidden");
}

export function middleMouseButtonNewTab(event, url) {
  if (event.button === 1) {
    let a = document.createElement("a");
    let ev = new MouseEvent("click", { ctrlKey: true });
    a.href = url + "?back_url=" + location.pathname;
    a.dispatchEvent(ev);
  }
}

export default {
  getCookie,
  updateInterfaceState,
  setInnerHTML,
  fetchDataFromHiddenInput,
  fetchDataFromHiddenInputWithParams,
  debounce,
  hasPermission,
  sendDeleteRequest,
  sendPatchRequest,
  sendPostRequest,
  truncate,
  isSidebarVisible,
  middleMouseButtonNewTab,
};

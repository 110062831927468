import { TabulatorFull as Tabulator, PageModule } from "tabulator-tables";

import * as columns from "./columns";
import * as menus from "./menus";
import * as filters from "./filters";
import * as editors from "./editors";

import FitDataStretchFrozen from "./FitDataStretchFrozen";
import { axios } from "../project";
import { debounce, getCookie } from "../utils";

const _parseRemoteData = function (data) {
  var margin;

  if (typeof data.last_page === "undefined") {
    console.warn(
      gettext("Remote Pagination Error - Server response missing '") +
        (this.options("dataReceiveParams").last_page || "last_page") +
        gettext("' property"),
    );
  }

  if (data.data) {
    this.max = parseInt(data.last_page) || 1;

    this.remoteRowCountEstimate =
      typeof data.last_row !== "undefined"
        ? data.last_row
        : data.last_page * this.size -
          (this.page == data.last_page ? this.size - data.data.length : 0);

    if (this.progressiveLoad) {
      switch (this.mode) {
        case "progressive_load":
          if (this.page == 1) {
            this.table.rowManager.setData(data.data, false, this.page == 1);
          } else {
            this.table.rowManager.addRows(data.data);
          }

          if (this.page < this.max) {
            setTimeout(() => {
              this.nextPage();
            }, this.table.options.progressiveLoadDelay);
          }
          break;

        case "progressive_scroll":
          data =
            this.page === 1
              ? data.data
              : this.table.rowManager.getData().concat(data.data);

          this.table.rowManager.setData(data, true, this.page == 1);

          margin =
            this.table.options.progressiveLoadScrollMargin ||
            this.table.rowManager.element.clientHeight * 2;

          if (
            this.table.rowManager.element.scrollHeight <=
            this.table.rowManager.element.clientHeight + margin
          ) {
            if (this.page < this.max) {
              setTimeout(() => {
                this.nextPage();
              });
            }
          }
          break;
      }

      return false;
    } else {
      // left = this.table.rowManager.scrollLeft;
      this.dispatchExternal("pageLoaded", this.getPage());
      // this.table.rowManager.scrollHorizontal(left);
      // this.table.columnManager.scrollHorizontal(left);
    }
  } else {
    console.warn(
      gettext("Remote Pagination Error - Server response missing '") +
        (this.options("dataReceiveParams").data || "data") +
        gettext("' property"),
    );
  }

  return data.data;
};
/*
Monkey patch fix for horizontal scroll jumping when loading data from remote source
https://github.com/olifolkerd/tabulator/issues/3450#issuecomment-1028694709
*/
PageModule.prototype._parseRemoteData = _parseRemoteData;

// Add custom table layout
Tabulator.moduleBindings.layout.modes.fitDataStretchFrozen =
  FitDataStretchFrozen;

const tabulatorConfig = JSON.parse(
  document.getElementById("tabulatorConfig").textContent,
);

export function initializeTable({
  tableId = "",
  ajaxURL = "",
  columns = [],
  rowContextMenu = null,
  selectable = "highlight",
  height = "70vh",
  maxHeight = undefined,
  tabFilters = null,
  customTab = null,
  rowFormatter = null,
  rowClickPopup = undefined,
  initialFilter = [],
  initialHeaderFilter = [],
  initialSort = [],
  disableFilterPersistence = false,
  disableSortPersistence = false,
  layout = "fitDataStretchFrozen",
  fullTable = false,
} = {}) {
  const activeTab = document.querySelector(`${tabFilters} .active`);
  if (activeTab) {
    initialFilter.push({
      field: activeTab.dataset.filter,
      type: "=",
      value: activeTab.dataset.value,
    });
  }

  if (rowContextMenu) {
    rowContextMenu = rowContextMenu.filter((item) => item);
  }

  const saveConfig = (id, type, data) => {
    axios.post(
      `/api/users/tabulator/${id}/${type}/`,
      {
        data: JSON.stringify(data),
      },
      {
        headers: {
          "X-CSRFTOKEN": getCookie("csrftoken"),
        },
      },
    );
  };

  const saveColumns = debounce((id, data) => {
    saveConfig(id, "columns", data);
  }, 300);

  const saveSort = debounce((id, data) => {
    saveConfig(id, "sort", data);
  }, 300);

  const saveFilters = debounce((id, data) => {
    saveConfig(id, "headerFilter", data);
  }, 300);

  const table = new Tabulator(tableId, {
    ajaxURL: ajaxURL,
    ajaxURLGenerator: function (url, config, params) {
      const urlParams = {};
      if (!fullTable) {
        params["limit"] = params.size;
        params["offset"] = (params.page - 1) * params.size;
        urlParams["limit"] = params.size;
        urlParams["offset"] = (params.page - 1) * params.size;
      }
      if (params.sort.length > 0) {
        const order =
          params.sort[0].dir === "asc"
            ? params.sort[0].field
            : `-${params.sort[0].field}`;
        urlParams["order"] = order.replaceAll(".", "__");
      }
      if (params.filter.length > 0) {
        params.filter.forEach((f) => {
          if (f.value.start || f.value.end) {
            if (f.value.start) {
              urlParams[`${f.field.replaceAll(".", "__")}__gte`] =
                f.value.start;
            }
            if (f.value.end) {
              urlParams[`${f.field.replaceAll(".", "__")}__lte`] = f.value.end;
            }
          } else {
            urlParams[f.field.replaceAll(".", "__")] = f.value;
          }
        });
      }
      const urlParamsString = new URLSearchParams(urlParams).toString();
      this.externalEvents.dispatch("ajaxURLGenerated", url, urlParamsString);
      return url + "?" + urlParamsString;
    },
    ajaxResponse: function (url, params, response) {
      this.externalEvents.dispatch("ajaxResponseLoaded", response);
      return {
        last_page: Math.ceil(response.count / params.size),
        data: fullTable ? response : response.items,
      };
    },
    height: height,
    minHeight: "15vh",
    maxHeight: maxHeight,
    layout: layout,
    progressiveLoad: "scroll",
    paginationSize: 50,
    filterMode: "remote",
    initialFilter: initialFilter,
    initialHeaderFilter: initialHeaderFilter,
    initialSort: initialSort,
    sortMode: "remote",
    movableColumns: true,
    selectable: selectable,
    persistence: {
      sort: !disableSortPersistence,
      columns: ["width", "visible"],
      headerFilter: !disableFilterPersistence,
    },
    persistenceWriterFunc: function (id, type, data) {
      switch (type) {
        case "columns":
          saveColumns(id, data);
          break;
        case "sort":
          saveSort(id, data);
          break;
        case "headerFilter":
          saveFilters(id, data);
          break;
      }
    },
    persistenceReaderFunc: function (id, type) {
      try {
        return tabulatorConfig[id][type];
      } catch (e) {
        return false;
      }
    },
    columns: columns,
    rowContextMenu: rowContextMenu,
    rowFormatter: rowFormatter,
    rowClickPopup: rowClickPopup,
    placeholder: function () {
      if (this.getHeaderFilters().length === 0) {
        return gettext("No data");
      }
      const container = document.createElement("div");
      container.className = "tabulator-placeholder-contents";
      container.textContent = gettext("No matching data");
      const clearButton = document.createElement("button");
      clearButton.className = "btn btn-green";
      clearButton.textContent = gettext("Clear filters");
      const that = this;
      clearButton.addEventListener("click", function () {
        that.clearHeaderFilter();
      });
      container.appendChild(clearButton);
      return container;
    },
  });

  if (tabFilters !== null && tabFilters.length > 0) {
    document.querySelectorAll(`${tabFilters} .tab-button`).forEach((btn) => {
      btn.addEventListener("click", function () {
        if (document.querySelector(tabFilters).querySelector(".active")) {
          document
            .querySelector(tabFilters)
            .querySelector(".active")
            .classList.remove("active");
        } else {
          const tableButton = document.querySelector(customTab);
          tableButton.classList.remove("active");
          document.querySelector(tableButton.dataset.tabtable).style.display =
            "none";
          document.querySelector(tableId).style.removeProperty("display");
        }
        table.setFilter(btn.dataset.filter, "=", btn.dataset.value);
        btn.classList.add("active");
        table.externalEvents.dispatch(
          "tabFilterChanged",
          btn.dataset.filter,
          btn.dataset.value,
        );
      });
    });
  }
  // render filtering indicators
  const rawTableId = tableId.slice(1);
  const active_filters = Array();
  function renderUsedMenuDot() {
    const menuButton = document
      .getElementById(rawTableId)
      .getElementsByClassName("table-header-menu")[0];
    const columns = table.getColumns();
    if (menuButton) {
      if (columns.some((c) => !c.isVisible()) || active_filters.length > 0) {
        menuButton.classList.add("filtered_or_modified");
      } else {
        menuButton.classList.remove("filtered_or_modified");
      }
    }
  }
  table.on("dataFiltered", (filters, rows) => {
    active_filters.splice(0);
    filters.forEach((filter) => {
      const column = table.getColumn(filter.field);
      if (column) {
        active_filters.push(filter.field);
      }
    });
  });
  table.on("renderComplete", function () {
    Array.from(
      document
        .getElementById(rawTableId)
        .getElementsByClassName("already_filtered"),
    ).forEach((element) => {
      element.classList.remove("already_filtered");
    });
    active_filters.forEach((filter) => {
      const headerElement = document
        .getElementById(rawTableId)
        .querySelector(`div[tabulator-field="${filter}"]`);
      headerElement.firstElementChild.classList.add("already_filtered");
    });
    renderUsedMenuDot();
  });
  table.on("columnVisibilityChanged", function (column, visible) {
    renderUsedMenuDot();
  });

  return table;
}

export default {
  initializeTable,
  columns,
  editors,
  filters,
  menus,
};

import { updateInterfaceState } from "./utils";
const sidebar_status = {
  visible: {
    sidebar_hidden: false,
  },
  hidden: {
    sidebar_hidden: true,
  },
};

const toggle_btn = document.querySelector(".toggle-sidebar");
const sidebar = document.querySelector("#sidebar");
const hamburger_btn = document.querySelector(".hamburger");

if (toggle_btn)
  toggle_btn.addEventListener("click", function (e) {
    e.preventDefault();
    sidebar.classList.toggle("hidden");
    this.querySelector("i").classList.toggle("fa-chevron-right");
    sidebar.classList.contains("hidden")
      ? updateInterfaceState(sidebar_status.hidden)
      : updateInterfaceState(sidebar_status.visible);
    sidebar.dispatchEvent(
      new CustomEvent("toggled", {
        detail: !sidebar.classList.contains("hidden"),
      }),
    );
  });

if (hamburger_btn)
  hamburger_btn.addEventListener("click", function (e) {
    e.preventDefault();
    if (window.innerWidth < 768 || window.innerHeight < 350) {
      const nav = document.querySelector("#sidebar nav");
      nav.classList.toggle("open");
      hamburger_btn.querySelector("i").classList.toggle("fa-close");
      if (nav.classList.contains("open")) {
        updateInterfaceState(sidebar_status.visible);
      } else {
        updateInterfaceState(sidebar_status.hidden);
      }
    } else {
      sidebar.classList.remove("hidden");
      toggle_btn.querySelector("i").classList.remove("fa-chevron-right");
      updateInterfaceState(sidebar_status.visible);
    }
  });

const ACTIONS = ["INC", "DEC"];

function operateOnInput(target, action) {
  const input_id = target
    .closest(".spinner-container")
    .getAttribute("data-input");
  const input = document.getElementById(input_id);
  const min = parseFloat(input.getAttribute("min")) || null;
  const max = parseFloat(input.getAttribute("max")) || null;
  if (action == ACTIONS[0]) {
    input.value < max || max == null ? input.stepUp() : "";
  } else {
    input.value > min || min == null ? input.stepDown() : "";
  }
  input.dispatchEvent(new Event("change"));
  const form = input.closest("form");
  if (form) form.dispatchEvent(new Event("change"));
}

export function initializeNumberSpinner() {
  /* CUSTOM SPINERS FOR INPUT TYPE NUMBER */
  const spinner_template = `
    <div class="spinner-container">
        <span class="up"><i class="fa-solid fa-caret-up"></i></span>
        <span class="down"><i class="fa-solid fa-caret-down"></i></span>
    </div>
    `;
  const number_inputs = document.querySelectorAll(".numberinput");
  number_inputs.forEach((el) => {
    const parent = el.closest("div");
    if (
      !parent.querySelector("input").disabled &&
      !parent.querySelector(".spinner-container")
    ) {
      el.insertAdjacentHTML("afterend", spinner_template);
      parent
        .querySelector(".spinner-container")
        .setAttribute("data-input", el.id);
    }
  });

  document.querySelectorAll(".spinner-container .up").forEach((el) => {
    el.addEventListener("click", function (e) {
      operateOnInput(e.target, ACTIONS[0]);
    });
  });

  document.querySelectorAll(".spinner-container .down").forEach((el) => {
    el.addEventListener("click", function (e) {
      operateOnInput(e.target, ACTIONS[1]);
    });
  });
}

initializeNumberSpinner();

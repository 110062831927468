import moment from "moment";
import Sortable from "sortablejs";
import "@fortawesome/fontawesome-free/css/all.css";
import axios from "axios";
import { DateTime } from "luxon";
import Holidays from "date-holidays";
import Chart from "chart.js/auto";
import Masonry from "masonry-layout";
import { TabulatorFull as Tabulator } from "tabulator-tables";

import "choices.js/public/assets/styles/choices.min.css";
import "tippy.js/dist/tippy.css";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "frappe-gantt/src/gantt.scss";
import "../sass/project.scss";
import { getCookie } from "./utils";
importFonts(getCookie("django_language"));

import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-polylineoffset/leaflet.polylineoffset";
import { make_gantt } from "./gantt";
import alerts from "./alerts";
import tables from "./tabulator/tables";
import utils from "./utils";
import choices from "./choices";
import datetime from "./datetime";
import modals from "./modals";
import tooltips from "./tooltips";
import sidenav from "./sidenav";
import prompts from "./prompts";
import geolocation from "./utils/geolocation";
import files from "./files";
import { default as splitSlider } from "./split-slider";
import { initializeMultiformSaveBanner } from "./utils/multiform-save-banner";
import { goBack } from "./utils/back-btn";

import "./interface";

window.DateTime = DateTime;

document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

document.addEventListener("paste", function (event) {
  if (event.target.name === "zip_code") {
    if (
      event.target.value === "" ||
      event.target.value.substring(
        event.target.selectionStart,
        event.target.selectionEnd,
      ) === event.target.value
    ) {
      event.preventDefault();
      const pasted = (event.clipboardData || window.clipboardData).getData(
        "text",
      );
      event.target.value = pasted.trim();
      event.target.dispatchEvent(new Event("change"));
    }
  }
});

window.Tabulator = Tabulator;
window.addEventListener("DOMContentLoaded", async function () {
  try {
    window.userUnits = await bundle.utils.fetchDataFromHiddenInput(
      "#user-units-api",
    );
    if (window.userUnits?.detail === "Unauthorized") {
      throw new Error();
    }
  } catch (e) {
    window.userUnits = {
      date_format: "m/d/Y",
      time_format: "h:i K",
      distance_fields: "mi",
      length_fields: "ft",
      temperature_fields: "F",
      weight_fields: "lb",
    };
  }

  window.dataTimeFieldFormats = {
    dateFormat: userUnits.date_format,
    timeFormat: userUnits.time_format,
  };
  document.dispatchEvent(new CustomEvent("projectsInitialized"));
});

function importFonts(language) {
  if (language === "ro") {
    import("../sass/fonts_ro.scss");
  } else {
    import("../sass/fonts.scss");
  }
}

export {
  tables,
  axios,
  utils,
  alerts,
  choices,
  moment,
  modals,
  datetime,
  Sortable,
  tooltips,
  sidenav,
  prompts,
  geolocation,
  L,
  make_gantt,
  files,
  Holidays,
  splitSlider,
  Chart,
  Masonry,
  initializeMultiformSaveBanner,
  goBack,
};

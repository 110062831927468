import { initializeDateTimeFields } from "../../datetime";
export default function (cell, onRendered, success, cancel, editorParams) {
  const container = document.createElement("div");
  container.style.textAlign = "start";
  const input = document.createElement("input");
  input.setAttribute("class", "dateinput");
  input.style.width = "100%";
  input.style.padding = "4px";
  input.style.boxSizing = "border-box";
  input.value = cell.getValue();
  function buildValues() {
    success(input.value);
  }
  input.addEventListener("change", buildValues);
  container.appendChild(input);
  initializeDateTimeFields(container, editorParams);
  return container;
}

export async function goBack() {
  history.back();
}

document.addEventListener("projectsInitialized", async function () {
  document.querySelectorAll(".back-btn").forEach((el) => {
    if (document.referrer.indexOf(window.location.host) !== -1) {
      if (window.location.href.indexOf("?") > -1) {
        const urlParams = new URLSearchParams(window.location.search);
        const back_url = urlParams.get("back_url");
        const current_url = window.location.pathname;
        window.history.replaceState({}, "", current_url);
        el.addEventListener("click", (e) => {
          window.location.href = back_url;
        });
      } else {
        el.addEventListener("click", goBack);
      }
      el.removeAttribute("href");
      el.style.cursor = "pointer";
    }
  });
});

async function onSliderChange(id, value, valueToSplit) {
  const container = document.getElementById(id);
  const inputFrom = container.querySelector("input[name='from']");
  const inputTo = container.querySelector("input[name='to']");
  inputFrom.value = valueToSplit - value;
  inputTo.value = value;
}

async function onFromChange(id, value, valueToSplit) {
  const container = document.getElementById(id);
  const slider = container.querySelector("input[type='range']");
  const inputTo = container.querySelector("input[name='to']");
  slider.value = valueToSplit - value;
  inputTo.value = valueToSplit - value;
}

async function onToChange(id, value, valueToSplit) {
  const container = document.getElementById(id);
  const slider = container.querySelector("input[type='range']");
  const inputFrom = container.querySelector("input[name='from']");
  slider.value = value;
  inputFrom.value = valueToSplit - value;
}

export function splitSlider(valueToSplit) {
  const container = document.createElement("div");
  container.setAttribute("class", "numberrangeinput");
  container.setAttribute("id", Math.random().toString(16).slice(2));
  const slider = document.createElement("input");
  slider.type = "range";
  slider.defaultValue = 0;
  slider.max = valueToSplit;
  slider.min = 0;
  slider.step = 1;
  slider.setAttribute(
    "oninput",
    `bundle.splitSlider.onSliderChange('${container.id}', this.value, ${valueToSplit})`,
  );

  const inputFrom = document.createElement("input");
  inputFrom.type = "number";
  inputFrom.name = "from";
  inputFrom.defaultValue = valueToSplit;
  inputFrom.max = valueToSplit;
  inputFrom.min = 0;
  inputFrom.style.maxWidth = "80px";
  inputFrom.style.width = "40%";
  inputFrom.setAttribute(
    "onchange",
    `bundle.splitSlider.onFromChange('${container.id}', this.value, ${valueToSplit})`,
  );

  const inputTo = inputFrom.cloneNode();
  inputTo.defaultValue = 0;
  inputTo.name = "to";
  inputTo.setAttribute(
    "onchange",
    `bundle.splitSlider.onToChange('${container.id}', this.value, ${valueToSplit})`,
  );

  container.appendChild(inputFrom);
  container.appendChild(slider);
  container.appendChild(inputTo);

  return container;
}

export default {
  splitSlider,
  onSliderChange,
  onFromChange,
  onToChange,
};

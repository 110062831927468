import { axios } from "./project";
import { getCookie, truncate } from "./utils";
import { addAlert } from "./alerts";
import { createModal, initializeModalForm } from "./modals";
import { textAreaPrompt } from "./prompts";
import { initializeTooltip } from "./tooltips";

export function initialize(container) {
  const fieldLabel = container.querySelector(".fileupload-label");
  const image = container.classList.contains("image");
  const select = container.querySelector("select");

  select.querySelectorAll("option[selected]").forEach(async function (option) {
    if (option.value === "") return;
    let file_data = null;
    let is_image = image;
    try {
      const response = await axios.get(
        `/api/files/${option.value}/thumbnail/64/SQUARE/`,
      );
      file_data = response.data;
      is_image = true;
    } catch {
      const response = await axios.get(`/api/files/${option.value}/`);
      file_data = response.data;
    }
    addUploadedFile(
      {
        is_image: is_image,
        file: file_data,
        name: option.text,
        id: option.value,
      },
      container,
    );
  });

  if (select.disabled) return;

  fieldLabel.addEventListener("dragover", function (e) {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    fieldLabel.classList.add("dragover");
  });

  fieldLabel.addEventListener("dragenter", function (e) {
    e.stopPropagation();
    e.preventDefault();
    fieldLabel.classList.add("dragover");
  });

  fieldLabel.addEventListener("dragleave", function (e) {
    e.stopPropagation();
    e.preventDefault();
    fieldLabel.classList.remove("dragover");
  });

  fieldLabel.addEventListener("dragend", function (e) {
    e.stopPropagation();
    e.preventDefault();
    fieldLabel.classList.remove("dragover");
  });

  fieldLabel.addEventListener("drop", (e) => {
    e.stopPropagation();
    e.preventDefault();
    fieldLabel.classList.remove("dragover");

    handleFiles(container, e.dataTransfer.files);
  });

  const fileInput = container.querySelector("input");
  fileInput.addEventListener("change", function (e) {
    handleFiles(container, this.files);
  });

  const selectButton = container.querySelector(".fileupload-select");
  selectButton.addEventListener("click", function (e) {
    fileInput.click();
  });
}

export function initializeFileUpload(element) {
  element.querySelectorAll(".fileupload-container").forEach((element) => {
    initialize(element);
  });
}

export function addUploadedFile(fileData, container) {
  const uploadedList = container.querySelector(".fileupload-list");
  const multiple = container.classList.contains("multiple");
  const disabled = container.querySelector("select").disabled;
  const deleteButton = `
      <button type="button" class="file-delete" data-id="${fileData.id}">
        <i class="fa fa-times file-delete-icon"></i>
      </button>`;
  let fileThumbnailUrl = "";
  let fileImageUrl = "";
  if (fileData.is_image) {
    fileThumbnailUrl = fileData.file.thumbnail_url
      ? fileData.file.thumbnail_url
      : fileData.file;
    fileImageUrl = fileData.file.image_url
      ? fileData.file.image_url
      : fileData.file;
  }

  if (!multiple) {
    uploadedList.innerHTML = "";
  }
  uploadedList.insertAdjacentHTML(
    "beforeend",
    `
      <div class="file">
        <div class="thumbnail${fileData.is_image ? " thumbnail-frame" : ""}">
            <img src="${fileThumbnailUrl}" class="${
              fileData.is_image ? "" : "hidden"
            }img-thumbnail" data-preview="${fileImageUrl}" data-name="${
              fileData.name
            }">
            <i class="fa fa-file-lines ${
              fileData.is_image ? "hidden" : ""
            }"></i>
        </div>
        </a>
        <div class="file-container" data-url="/api/files/${
          fileData.id
        }/comment/" data-tooltip-placement="top" data-tooltip="${
          fileData.file.comment ? fileData.file.comment : fileData.name
        }" data-comment="${fileData.file.comment ? true : ""}">${
          fileData.file.comment
            ? truncate(fileData.file.comment, 90)
            : fileData.name
        }</div>
        ${disabled ? "" : deleteButton}
      </div>
    `,
  );
}

function handleFiles(container, files) {
  const select = container.querySelector("select");
  const image = container.classList.contains("image");
  const acceptedMimes = select.dataset.mime
    ? JSON.parse(select.dataset.mime)
    : null;

  [...files].forEach((file) => {
    if (image && !acceptedMimes.includes(file.type)) {
      addAlert("error", gettext("Only images are accepted."));
      return;
    }
    uploadFile(file, container);
  });
}

export async function uploadFile(file, container) {
  const select = container.querySelector("select");

  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(select.dataset.upload, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "X-CSRFTOKEN": getCookie("csrftoken"),
    },
  });
  if (response.status === 200) {
    const option = document.createElement("option");
    option.value = response.data.id;
    option.text = response.data.name;
    option.selected = true;
    select.add(option);
    response.data.file = URL.createObjectURL(file);
    addUploadedFile(response.data, container);
  }
}

export function initializeFilePreview(file) {
  file.addEventListener("click", function (e) {
    if (file.dataset.preview !== "None") {
      e.preventDefault();
      const divComment = `<div class="preview-comment">
                <span>${gettext("Comments:")}</span>
                ${file.dataset.tooltip}
              </div>`;
      createModal(
        file.dataset.filename,
        `
              <div class="preview-content">
              <img src="${file.dataset.preview}" class="modal-image">
              ${file.dataset.comment ? divComment : ""}
              </div>
            `,
      );
    }
  });
}

export function initializeFileGallery(element) {
  element.querySelectorAll(".file-gallery").forEach((gallery) => {
    gallery.querySelectorAll(".file").forEach((file) => {
      initializeFilePreview(file);
    });

    const openUploadModal = gallery.querySelector(".open-upload-modal");
    if (openUploadModal) {
      openUploadModal.addEventListener("click", async function () {
        const response = await fetch(
          `${openUploadModal.dataset.url}?${new URLSearchParams({
            field: openUploadModal.dataset.field,
          })}`,
          {
            headers: {
              ajax: true,
            },
          },
        );
        const photoForm = await response.text();
        const modal = createModal(gettext("Add files"), photoForm);
        initializeModalForm(modal, undefined, dataTimeFieldFormats);

        modal.addEventListener("modal-form-saved", function (e) {
          setTimeout(function () {
            window.location.reload();
          }, 300);
        });
      });
    }
  });
}

document.addEventListener("click", async function (e) {
  if (
    e.target.classList.contains("file-delete") ||
    e.target.classList.contains("file-delete-icon")
  ) {
    const container = e.target.closest(".fileupload-container");
    const select = container.querySelector("select");
    const form = container.closest("form");
    const option = container.querySelector(
      `select option[value="${e.target.dataset.id}"]`,
    );
    option.selected = false;
    if (form) form.dispatchEvent(new Event("change"));
    e.target.closest(".file").remove();
  } else if (
    e.target.classList.contains("img-thumbnail") ||
    e.target.classList.contains("thumbnail")
  ) {
    const container = e.target.classList.contains("thumbnail")
      ? e.target.querySelector(".img-thumbnail")
      : e.target;
    if (container.dataset.preview !== "") {
      const containerComment = e.target.parentElement.nextElementSibling;
      const divComment = `<div class="preview-comment">
                <span>${gettext("Comments:")}</span>
                ${containerComment.dataset.tooltip}
              </div>`;
      e.preventDefault();

      createModal(
        container.dataset.name,
        `
              <div class="preview-content">
              <img src="${container.dataset.preview}" class="modal-image">
              ${containerComment.dataset.comment ? divComment : ""}
              </div>
            `,
      );
    }
  } else if (e.target.classList.contains("file-container")) {
    const commentValue = e.target;
    const result = await textAreaPrompt({
      options: {
        title: gettext("Change comment"),
        confirmText: gettext("Submit"),
      },
      inputValue: commentValue.dataset.comment
        ? commentValue.dataset.tooltip
        : "",
      inputLabel: gettext("Comment"),
    });
    if (result.isConfirmed && result.value) {
      try {
        const response = await bundle.utils.sendPatchRequest(
          commentValue.dataset.url,
          {
            comment: result.value,
          },
        );
        bundle.alerts.addAlert("success", response.data.message);
        commentValue.textContent = new DOMParser().parseFromString(
          truncate(result.value, 90),
          "text/html",
        ).documentElement.textContent;
        commentValue.dataset.tooltip = result.value;
        commentValue._tippy.setContent(result.value);
      } catch (error) {
        bundle.alerts.addAlert("error", error.response.data.message);
      }
    }
  }
});

document.addEventListener("mouseover", async function (e) {
  if (e.target.dataset.tooltip) {
    e.preventDefault();
    if (e.target._tippy) return;
    initializeTooltip(e.target);
  }
});

window.addEventListener("DOMContentLoaded", async function () {
  await initializeFileUpload(document);
  await initializeFileGallery(document);
});

export default {
  initialize,
  initializeFileUpload,
  initializeFileGallery,
  uploadFile,
};

import ClearFilterMenu from "./ClearFilterMenu";
import HideColumnMenu from "./HideColumnMenu";

export { default as ClearFilterMenu } from "./ClearFilterMenu";
export { default as HideColumnMenu } from "./HideColumnMenu";
export { default as ClearAllFiltersMenu } from "./ClearAllFiltersMenu";
export { default as ShowAllColumnsMenu } from "./ShowAllColumnsMenu";
export { default as ColumnSelectMenu } from "./ColumnSelectMenu";

export function ClearFilterHideColumnMenu() {
  return ClearFilterMenu().concat(HideColumnMenu());
}

export default function () {
  return [
    {
      label: gettext("Show all columns"),
      action: function (e, column) {
        const columns = column.getTable().getColumns();
        for (let column of columns) {
          column.show();
        }
      },
    },
  ];
}
